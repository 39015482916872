<!-- 通知公告 -->

<template>
	<div class="notifica-announc main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :apiName="Notification.getNoticeLists"
				:filters="filters" :columns="tableColumns" @onPowerStation="onPowerStation">
				<template #operate v-if="authData.indexOf('n_rHSXDGoEOPqR8W5znbwk3PEmwOtm') != -1">
					<el-button type="primary" round @click="onAddNoticeBtn">
						<el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
						新增公告
					</el-button>
				</template>

				<template #editBtn="{row}">
					<el-button @click="onEditBtn(row)"
						v-if="authData.indexOf('n_4wSkcVS8K7lpEZ7KvRp1EwmuOawZ') != -1 && row.no_status !== 3">编辑
					</el-button>
				</template>
			</common-table>
		</div>

		<!-- 新增、编辑公告 -->
		<w-dialog ref="addDialog" class="add-dialog" :title="isAdd ? '新增公告' : '编辑公告'" width="40%" btnWidth="140px"
			top="20vh" :confirmText="isAdd ? '确认新增' : '确认保存'" @wConfirm="addConfirmBtn">
			<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="公告标题" prop="no_title">
							<el-input v-model="ruleForm.no_title" placeholder="请输入公告标题"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="公告时间" prop="no_time">
							<el-date-picker style="background: #f2f2f2;" v-model="ruleForm.no_time" type="daterange"
								range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" format="YYYY-MM-DD"
								value-format="YYYY-MM-DD" />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="关联链接" prop="no_link">
							<el-input v-model="ruleForm.no_link" placeholder="请输入本平台相关活动优惠券链接，其余链接无效"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="公告内容" prop="no_content">
							<el-input v-model="ruleForm.no_content" type="textarea" rows="4" placeholder="请输入公告内容">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>

		<!-- 绑定电站-->
		<w-dialog ref="refDialog" class="add-dialog" title="绑定电站" width="50%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmStation">
			<el-table ref="tableref" :data="tableStation" stripe style="width: 100%" element-loading-text="数据加载中"
				tooltip-effect="light" @select="stationSelectionChange" @select-all="selectionAllChange" height='450'
				:row-key="getStationKeys">
				<el-table-column type="selection" width="55" :reserve-selection="true" />
				<el-table-column prop="s_name" label="电站名称" show-overflow-tooltip />
				<el-table-column prop="s_no" label="电站编号" show-overflow-tooltip />
				<el-table-column prop="s_address" label="区域" show-overflow-tooltip />
			</el-table>
			<div class="s-m-t">已选<span class="select-quant title-num-green">{{ selectStation.length }}</span></div>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		computed,
		watch,
	} from "vue";
	import { Notification, } from "@/plugins/api.js";
	import { ElMessage, ElMessageBox, } from "element-plus";
	import { useStore } from "vuex";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 表格对象 */
	const roleTable = ref(null);
	const currentRow = ref({});  // 当前编辑数据
	// 新增、编辑公告弹框对象
	const addDialog = ref(null);
	/** 是否新增 */
	const isAdd = ref(true);
	/** 表单对象 */
	const addForm = ref(null);
	/** 表单数据对象 */
	const ruleForm = ref(
		{
			no_title: '',  // 公告标题
			no_time: '',  // 公告时间
			no_start_time: '',  // 公告开始时间
			no_end_time: '',  // 公告结束时间
			no_link: '',  // 公告站内链接
			no_content: '',  // 公告内容
		}
	);
	/** 表单规则对象 */
	const rules = reactive({
		no_title: [{ required: true, message: "请输入公告标题", trigger: "blur", }],
		no_time: [{ required: true, message: "请选择公告时间", trigger: "blur", }],
		no_content: [{ required: true, message: "请输入公告内容", trigger: "blur", }],
	});
	const refDialog = ref(null); // 弹框对象
	const tableStation = ref([]); // 表格数据
	const selectStation = ref([]); // 选中绑定电站数据
	const tableref = ref(null); // 表格对象
	const currentRangeRow = ref(''); // 绑定电站当前行数据

	/** 筛选条件列表 */
	const filters = ref([
		{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入标题名称",
		},
		{
			filterType: "select",
			name: "status",
			value: "",
			placeholder: "请选择是否生效",
			options: [
				{ id: 1, name: '未生效' },
				{ id: 2, name: '已生效' },
				{ id: 3, name: '已过期' },
			],
			lab: "name",
			val: "id",
		},
	]);
	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "no_title",
			label: "公告标题",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "no_content",
			label: "公告内容",
			active: "orderQuantity",
			showTooltip: true,
		},
		{
			prop: "start_end_time",
			label: "公告时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			type: "block",
			prop: "station_count",
			label: "公告电站",
			active: "onPowerStation",
			minWidth: 100,
			token: "n_JgrZi2LT7ayCwI4FmP5nr9DUB1jd",
		},
		{
			prop: "status_text",
			label: "是否生效",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "no_link",
			label: "关联链接",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "created_time",
			label: "创建时间",
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [{
				name: "编辑",
				action: "editBtn",
				type: "customRender",
			}],
		},
	]);
	/**
	 * 
	 * 新增公告按钮
	 * 
	 * **/
	const onAddNoticeBtn = () => {
		ruleForm.value = {
			no_title: '',  // 公告标题
			no_time: '',  // 公告时间
			no_start_time: '',  // 公告开始时间
			no_end_time: '',  // 公告结束时间
			no_link: '',  // 公告站内链接
			no_content: '',  // 公告内容
		};
		isAdd.value = true;
		addDialog.value.show();
	}
	/**
	 * 
	 * 编辑公告按钮
	 * 
	 * **/
	const onEditBtn = (row) => {
		isAdd.value = false;
		getNoticeInfo(row.no_id, 1);
		addDialog.value.show();
	}
	/**
	 * 
	 * 新增、编辑公告确定按钮
	 * 
	 * **/
	const addConfirmBtn = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				let url = '', msg = '', params = {
					no_title: ruleForm.value.no_title,
					no_link: ruleForm.value.no_link,
					no_content: ruleForm.value.no_content,
					no_start_time: ruleForm.value.no_time[0],
					no_end_time: ruleForm.value.no_time[1],
				};
				if (isAdd.value) {
					url = 'addNotice';
					msg = '新增成功！';
				} else {
					url = 'editNotice';
					msg = '编辑成功！';
					params.no_id = currentRow.value.no_id;
				}
				addDialog.value.isLoading = true; // 按钮加载中状态
				Notification[url](params).then((res) => {
					addDialog.value.isLoading = false;
					if (res.Code === 200) {
						roleTable.value.tableLoad(); // 重新获取列表数据
						ElMessage.success(msg); // 成功提示
						addDialog.value.close(); // 关闭弹框
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
		});
	}
	/**
	 * 
	 * 获取详情
	 * 
	 * **/
	const getNoticeInfo = (no_id, type) => {
		Notification.getNoticeInfo({
			no_id,
			type
		}).then((res) => {
			if (res.Code === 200) {
				if (type == '1') {
					currentRow.value = res.Data[0];
					ruleForm.value.no_title = currentRow.value.no_title;
					ruleForm.value.no_time = [currentRow.value.no_start_time, currentRow.value.no_end_time];
					ruleForm.value.no_start_time = currentRow.value.no_start_time;
					ruleForm.value.no_end_time = currentRow.value.no_end_time;
					ruleForm.value.no_link = currentRow.value.no_link;
					ruleForm.value.no_content = currentRow.value.no_content;
				} else {
					selectStation.value = [];
					tableStation.value = res.Data;
					if (res.Data.length > 0) {
						res.Data.forEach(item => {
							if (item.tips == '1') {
								// 回显已绑定电站
								tableref.value.toggleRowSelection(item, true);
								selectStation.value.push(item);
							}
						})
					}
				}
			}
		});
	}
	/**
	 * 
	 * 点击公告电站
	 * 
	 * */
	const onPowerStation = (row) => {
		currentRangeRow.value = row;
		getNoticeInfo(row.no_id, 2);
		refDialog.value.show();
	}
	const getStationKeys = (row) => {
		return row.s_id;
	}
	/**
	 * 
	 * 选择绑定电站
	 * 
	 * */
	const stationSelectionChange = (arr, val) => {
		selectStation.value = arr;
		let selected = arr.length && arr.indexOf(val) !== -1;  // 判断是选中还是取消 true是选中，0或者false是取消选中
		if (selected) {
			// 只选中操作
			if (val.notice > 0) {
				// 判断是否已关联公告
				ElMessageBox.confirm(
					`当前电站已关联多个生效中公告，是否继续？`,
					'提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
				}
				).then(() => { }).catch(() => {
					// 取消选中状态
					tableref.value.toggleRowSelection(val, false);
				})
			}
		}
	}
	/**
	 * 
	 * 勾选全选
	 * 
	 * **/
	const selectionAllChange = (arr) => {
		selectStation.value = arr;
	}
	/**
	 * 
	 * 绑定电站确定按钮
	 * 
	 * **/
	const onConfirmStation = () => {
		let bindingData = [];
		// 判断是否有已绑定电站
		if (tableStation.value.length > 0) {
			tableStation.value.forEach(item => {
				if (item.tips == '1') {
					bindingData.push(item);
				}
			})
		}
		if (bindingData.length > 0) {
			// 如果有已绑定数据,全部取消
			if (selectStation.value.length == 0) {
				ElMessageBox.confirm(
					`是否确认取消所有已绑定电站`,
					'提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
				}
				).then(() => {
					bindNotice();
				}).catch(() => { })
			} else {
				bindNotice();
			}
		} else {
			bindNotice();
		}
	}
	/**
	 * 
	 * 绑定电站
	 * 
	 * **/
	const bindNotice = () => {
		let s_id = [];
		if (selectStation.value.length > 0) {
			// 处理选择电站数据
			selectStation.value.forEach(item => {
				s_id.push(item.s_id);
			})
		}
		let params = {
			no_id: currentRangeRow.value.no_id,
			st_id: s_id,
		};
		Notification.bindNotice(params).then((res) => {
			if (res.Code === 200) {
				roleTable.value.tableLoad(); // 重新获取列表数据
				ElMessage.success('操作成功！'); // 成功提示
				refDialog.value.close(); // 关闭弹框
			} else {
				ElMessage.error(res.Message);
			}
		});
	}

	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.notifica-announc {
		font-family: "Source Han Sans CN";

		.el-table__footer-wrapper tbody td.el-table__cell {
			background-color: #fff;
			border-color: #fff;
		}

		.el-table__fixed::before,
		.el-table::before,
		.el-table__fixed-right::before {
			background-color: #fff;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;

				.el-table__row {
					.el-table__cell {
						padding: 9px 0 !important;
					}
				}

			}
		}
	}
</style>